import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import GetDagcoins from "../../components/get-dagcoins";
import Headertransparent from "../../components/headertransparent";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import { Seo } from "../../components/seo";
import imageApple from "../img/apple.png";
import imageDesktop from "../img/desktop.svg";
import imageDown from "../img/down-1.svg";
import imageMacOS from "../img/macos.svg";
import imageMobile from "../img/mobile.svg";
import imageOne from "../img/1.svg";
import imageOnline from "../img/online.svg";
import imagePlay from "../img/play.png";
import imageThree from "../img/3.svg";
import imageThreeTwo from "../img/32.svg";
import imageTwo from "../img/2.svg";
import imageWeb from "../img/web.png";
import imageWin from "../img/win64.svg";
import "./index.css";

function IndexPage({ data }) {
  var NextUid = data.prismicWallet.alternate_languages[0].uid;

  if (data.prismicWallet.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicWallet.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  var SeoMeta = data.prismicWallet.data;
  return (
    <Layout htmllang={data.prismicWallet.lang}>
      <Headertransparent
        htmllang={data.prismicWallet.lang}
        langswitcher={NextUrl}
      />
      <Hero padding={175}>
        <div className="getfreewalletbg"></div>
        <div className="dagcoinwallet-headersection">
          <PrismicRichText field={SeoMeta.above_title.richText} />
          <PrismicRichText field={SeoMeta.above_desc.richText} />
          <a className="btn btn--orange" href="#getwalletref">
            {SeoMeta.above_button_text.text}
          </a>
        </div>
      </Hero>

      <div className="features-wallet-block whitebg">
        <Container className="featured-wallet-above">
          <Row>
            <Col xs={12} md={4} className="features-wallet-block__col">
              <img alt={imageOne} src={imageOne} />
              <PrismicRichText
                field={SeoMeta.after_above[0].first_col.richText}
              />
            </Col>
            <Col xs={12} md={4} className="features-wallet-block__col">
              <img alt={imageTwo} src={imageTwo} />
              <PrismicRichText
                field={SeoMeta.after_above[1].first_col.richText}
              />
            </Col>
            <Col xs={12} md={4} className="features-wallet-block__col">
              <img alt={imageThree} src={imageThree} />
              <PrismicRichText
                field={SeoMeta.after_above[2].first_col.richText}
              />
            </Col>
          </Row>
        </Container>

        <div
          id="getwalletref"
          className="choice-device-block__wrapper onlychoicebuttons"
        >
          <Container className="choice-device-block">
            <Row className="choice-device-block__title-row">
              <Col xs={10} md={7} className="choice-device-block__title-col">
                <div className="customimgs">
                  <img src={imageDown} alt="dagcoin" />
                </div>
                <PrismicRichText
                  field={SeoMeta.download_wallet_description.richText}
                />
              </Col>
            </Row>

            <Row className="choice-device-block__app-row">
              <Col xs={12} md={4} className="choice-device-block__app-col">
                <div className="customimgs">
                  <img src={imageMobile} alt="dagcoin" />
                </div>
                <PrismicRichText
                  field={SeoMeta.footer_content[0].second_col.richText}
                />
                <div className="choice-device-block__buttons">
                  <a href="https://play.google.com/store/apps/details?id=com.dagcoin.levercode">
                    <img src={imagePlay} alt="dagcoin" />
                  </a>
                  <a href="https://apps.apple.com/us/app/dagwallet/id1479848196">
                    <img src={imageApple} alt="dagcoin" />
                  </a>
                </div>
              </Col>

              <Col xs={12} md={4} className="choice-device-block__app-col">
                <div className="customimgs">
                  <img src={imageDesktop} alt="dagcoin" />
                </div>
                <PrismicRichText
                  field={SeoMeta.footer_content[1].second_col.richText}
                />
                <div className="choice-device-block__buttons">
                  <a
                    className="platformver"
                    href="https://download.dagcoin.org/latest/DagWallet-win64.exe"
                  >
                    <img alt={imageWin} src={imageWin} />
                  </a>
                  <a
                    className="platformver"
                    href="https://download.dagcoin.org/latest/DagWallet-win32.exe"
                  >
                    <img alt={imageThreeTwo} src={imageThreeTwo} />
                  </a>
                  <a
                    className="platformver"
                    href="https://download.dagcoin.org/latest/DagWallet-osx64.dmg"
                  >
                    <img alt={imageMacOS} src={imageMacOS} />
                  </a>
                  {/* <a href="https://download.dagcoin.org/latest/DagWallet-linux64.deb"><img src={require("../pages/img/linudx.svg")}/></a> */}
                </div>
              </Col>

              <Col xs={12} md={4} className="choice-device-block__app-col">
                <div className="customimgs">
                  <img src={imageOnline} alt="dagcoin" />
                </div>
                <PrismicRichText
                  field={SeoMeta.footer_content[2].second_col.richText}
                />
                <div className="choice-device-block__buttons">
                  <a href="https://wallet.dagcoin.org/">
                    <img src={imageWeb} alt="dagcoin" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { seo_title, seo_description } = data.prismicWallet.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        pathname={data.prismicWallet.uid}
        title={seo_title.text}
        // lang={data.prismicWallet.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicWallet(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        above_button_text {
          text
        }
        above_title {
          text
          richText
        }
        above_desc {
          text
          richText
        }
        after_above {
          first_col {
            text
            richText
          }
        }
        download_wallet_description {
          text
          richText
        }
        footer_content {
          second_col {
            text
            richText
          }
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`;
export default IndexPage;
